<template>
  <article class="glossary-item">
    <h2 class="glossary-header">
      <text-highlight :queries="queries" :caseSensitive="true">{{
        title
      }}</text-highlight>
    </h2>

    <p
      style="
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 100;
        -webkit-box-orient: vertical;
      "
      class="glossary-content"
    >
      <text-highlight :queries="queries" :caseSensitive="true">{{
        description
      }}</text-highlight>
    </p>

    <a :href="link" class="glossary-link" target="_blank">
      {{ linkTitle }}
    </a>
  </article>
</template>

<script>
import Vue from 'vue';
import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);

export default {
  name: 'GlossartyItem',
  data() {
    return {
      isDescriptionComplete: false
    };
  },

  props: {
    title: {
      type: String,
      required: true
    },
    queries: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    linkTitle: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.glossary-item {
  position: relative;
  margin: 0 0 15px 0;
  background: $white;
  padding: 20px 15px 15px 15px;
  border-radius: 10px;

  max-width: 900px;
}

.glossary-header {
  font-size: 18px;
}

.show-more,
.show-less {
  background: none;
  border: none;
  color: blue;
  font-size: 15px;
  font-style: italic;
  margin-bottom: 20px;
}

.btn-download {
  margin-top: 15px;
}

.glossary-link {
  text-decoration: underline !important;
  display: block;
}

.glossary-tags {
  padding-top: 5px;
}

.glossary-tag-item {
  margin-right: 5px;
}
</style>
