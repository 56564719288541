import axios from 'axios';
import Papa from 'papaparse';

export const letters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];

const strFromLocale = (str) => {
  const match = (letter) => {
    const letterMatch = (letter, normalizedLetter) => {
      const location = new Intl.Collator('pt-BR', {
        usage: 'search',
        sensitivity: 'base'
      }).compare(letter, normalizedLetter);

      return location == 0;
    };

    const normalizedLetter = letter
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/gi, '');

    if (letterMatch(letter, normalizedLetter)) {
      return normalizedLetter;
    }

    return letter;
  };

  // eslint-disable-next-line no-control-regex
  return str.toLowerCase().replace(/[^\u0000-\u007E]/g, match);
};

const textMatch = (value, items) => {
  const parsedItems = items.map(strFromLocale);
  const parsedValue = strFromLocale(value);

  return parsedItems.some((item) => {
    return item.includes(parsedValue);
  });
};

const sortByTitle = (a, b) => {
  return a.title.localeCompare(b.title);
};

const parseCSV = (glossary) => {
  const parsedCSV = Papa.parse(glossary, {
    skipEmptyLines: true,
    delimiter: ';;'
  }).data;

  return parsedCSV.map((item) => {
    return {
      title: item[0],
      description: item[1],
      link: item[2],
      linkTitle: item[3],
      tags: item[4].split(',')
    };
  });
};

const getCSV = async () => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const { data } = await axios.get('/data/glossaryUpdated.csv');
      resolve(parseCSV(data));
    }, 300);
  });
};

export const glossarySearch = async ({ search, startsWith }) => {
  const itens = await getCSV();

  const filteredValues = itens
    .filter((item) => {
      const matchFirstLetter = strFromLocale(item.title).startsWith(startsWith);
      const searchableFields = [
        item.title,
        item.description,
        item.linkTitle,
        ...item.tags
      ];
      return matchFirstLetter && textMatch(search, searchableFields);
    })
    .sort(sortByTitle);

  return Promise.resolve(filteredValues);
};
